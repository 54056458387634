.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Page background */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #1e1e2f;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

/* Centered Title Styling */
#page-title {
  text-align: center;
  padding: 20px 0;
  color: #ffffff;
}

#page-title h1 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

/* Health Bar Container */
.health-bar-container {
  width: 100%;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
}

/* Health Bar Fill for the Bot (Red) */
#health-bar-fill-red {
  height: 100%;
  background-color: #f44336;
  /* Red */
  width: 50%;
  /* Start with equal width */
  border-radius: 5px 0 0 5px;
  /* Round left side */
  transition: width 0.5s ease-out;
}

/* Health Bar Fill for the User (Green) */
#health-bar-fill-green {
  height: 100%;
  background-color: #8a2be2;
  /* Green */
  width: 50%;
  /* Start with equal width */
  border-radius: 0 5px 5px 0;
  /* Round right side */
  transition: width 0.5s ease-out;
}

#chat-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  /* Adjust to be 75% of the viewport height */
  max-width: 600px;
  margin: 20px auto;
  background-color: #1e1e2f;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
}

/* Chatbox area where messages are displayed */
.chatbox {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #1e1e2f;
  border-bottom: 1px solid #44475a;
}

.game-mode-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #8a2be2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.game-mode-button:hover {
  background-color: #45a049;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it overlays everything else */
  pointer-events: none;
  /* Prevent interaction with the modal overlay itself */
}

/* Modal Content */
.modal-content {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 400px;
  z-index: 1100;
  /* Higher z-index to ensure it overlays everything */
  pointer-events: all;
  /* Allow interaction with the modal content */
}

.game-mode-description {
  font-size: 14px;
  font-weight: bold;
  color: black;
  margin-top: 5px;
  margin-bottom: 20px;
  text-align: center;
  /* Align text to the left */
}

/* Deactivated Background (excluding chat container) */
.deactivated {
  pointer-events: none;
  /* Disable interaction */
  user-select: none;
  /* Prevent text selection */
}

.share-button-container {
  display: flex;
  flex-direction: row;
}

.share-icon {
  margin-left: auto;
  background: none;
  border: none;
  cursor: pointer;
  color: #8a2be2;
  /* Adjust color to match your design */
  z-index: 10;
  /* Ensure the button is above other content */
}

.share-icon:hover {
  color: #0056b3;
  /* Adjust hover color */
}

/* Play Again Button */
.play-again-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #8a2be2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.play-again-button:hover {
  background-color: #45a049;
}

.no-thanks-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.no-thanks-button:hover {
  background-color: #d32f2f;
}

/* Deactivate Chat Container */
#chat-container {
  pointer-events: auto;
  /* Allow interaction with the chat container */
  overflow-y: auto;
  /* Ensure scrolling is enabled */
  opacity: 1;
}

/* Styling for individual messages */
.message {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
}

/* Align user messages to the right */
.message-user {
  justify-content: flex-end;
}

/* Align bot messages to the left */
.message-bot {
  justify-content: flex-start;
}

.message-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.message-icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* If you want circular icons */
}

.message-user .message-icon {
  order: 2;
  /* Move icon to the right for user messages */
  margin-left: 10px;
  margin-right: 0;
}

/* Common message bubble styling */
.message-bubble {
  max-width: 75%;
  padding: 12px 18px;
  border-radius: 20px;
  font-size: 15px;
  line-height: 1.5;
  word-wrap: break-word;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* User message bubble styling */
.message-user .message-bubble {
  background-color: #8a2be2;
  color: #ffffff;
}

/* Bot message bubble styling */
.message-bot .message-bubble {
  background-color: #f1f1f1;
  color: #333333;
}

/* Markdown Styling */
.message-bubble h1,
.message-bubble h2,
.message-bubble h3,
.message-bubble h4,
.message-bubble h5,
.message-bubble h6 {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.message-bubble p {
  margin: 0;
  margin-bottom: 10px;
}

.message-bubble a {
  color: #007bff;
  text-decoration: none;
}

.message-bubble a:hover {
  text-decoration: underline;
}

.message-bubble code {
  background-color: #f1f1f1;
  color: #d63384;
  padding: 2px 4px;
  border-radius: 3px;
  font-family: "Courier New", Courier, monospace;
}

.message-bubble pre {
  background-color: #272822;
  color: #f8f8f2;
  padding: 12px;
  border-radius: 5px;
  overflow-x: auto;
  font-family: "Courier New", Courier, monospace;
  margin: 10px 0;
}

.message-bubble ul,
.message-bubble ol {
  margin: 0 0 10px 20px;
}

.message-bubble li {
  margin-bottom: 5px;
}

/* Input area styling */
#message-input-container {
  display: flex;
  justify-content: space-between;
  /* Distribute space between input and button */
  padding: 15px;
  background-color: #1e1e2f;
  border-top: 1px solid #44475a;
  width: 100%;
  box-sizing: border-box;
}

/* Styling for the input field */
#message-input {
  flex: 1;
  border: 1px solid #6272a4;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 16px;
  font-family: inherit;
  background-color: #2b2b3d;
  color: #f8f8f2;
  outline: none;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.5);
  transition: border-color 0.2s;
  resize: none;
  /* Prevent manual resizing */
  overflow: hidden;
  /* Hide scrollbar */
}

#message-input:focus {
  border-color: #bd93f9;
}

/* Styling for the send button */
#send-button {
  display: flex;
  background-color: #8a2be2;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
  align-self: center;
  /* Align the button vertically center */
  transition: background-color 0.2s;
}

#send-icon {
  width: 40px;
  height: 40px;
  fill: #ffff;
}

#send-button:hover {
  background-color: #9c30ff;
}

/* Media Queries for Responsiveness */

/* For small screens, like mobile devices */
@media (max-width: 600px) {
  #chat-container {
    width: calc(100% - 10px);
    /* Decrease padding to make it wider */
    margin: 10px auto;
    /* Reduce the top and bottom margins */
    border-radius: 10px;
  }

  #message-input-container {
    padding: 10px;
  }

  #page-title {
    padding: 10px 0;
    /* Reduce the top and bottom padding */
  }

  #message-input {
    font-size: 16px;
    padding: 8px 10px;
    /* Reduce padding */
  }

  #send-button {
    font-size: 16px;
    padding: 8px 10px;
    /* Reduce padding */
  }

  .message-bubble {
    font-size: 16px;
    padding: 10px 14px;
    /* Reduce padding */
  }
}

/* For medium screens, like tablets */
@media (min-width: 601px) and (max-width: 1024px) {
  #chat-container {
    max-width: 80%;
    margin: 20px auto;
  }

  #message-input {
    font-size: 14px;
    padding: 10px 15px;
  }

  #send-button {
    font-size: 14px;
    padding: 10px 20px;
  }

  .message-bubble {
    font-size: 15px;
    padding: 12px 18px;
  }
}

/* For large screens, like laptops and desktops */
@media (min-width: 1025px) {
  #chat-container {
    max-width: 800px;
    margin: 20px auto;
  }

  #message-input {
    font-size: 15px;
    padding: 12px 20px;
  }

  #send-button {
    font-size: 15px;
    padding: 12px 20px;
  }

  .message-bubble {
    font-size: 16px;
    padding: 14px 20px;
  }
}
